import { IconChevronDown, IconError } from '@krakentech/icons';
import clsx from 'clsx';
import { ErrorMessage, Field } from 'formik';
import { useTranslation } from 'next-i18next';
import {
	ChangeEvent,
	FocusEvent,
	KeyboardEvent,
	ReactElement,
	ReactNode,
	useEffect,
	useState,
} from 'react';

import { ColorHex } from '@/utils/constants/colors';
import { camelCaseToTitleCase } from '@/utils/formatters/camelCaseToTitleCase';

const fullWidthToHalfWidth = (str: string) =>
	str.replace(/[！-～]/g, (r) => String.fromCharCode(r.charCodeAt(0) - 0xfee0));

type AutocompleteSelectInputFieldProps<T> = {
	initialValue?: string;
	readOnly?: boolean;
	shouldHideIcon?: boolean;
	shouldHideLabel?: boolean;
} & SelectInputFieldProps<T>;

export function AutocompleteSelectInputField<T>({
	name,
	disabled,
	theme = 'dark',
	label,
	placeholder,
	options,
	errors,
	values,
	setFieldValue,
	touched,
	handleBlur,
	shouldHideIcon = false,
	shouldHideLabel = false,
	initialValue = '',
	readOnly = false,
}: AutocompleteSelectInputFieldProps<T>): ReactElement {
	/* Field State logic */
	const [fieldState, setFieldState] = useState<
		'ACTIVE' | 'IDLE' | 'INVALID_EMPTY' | 'VALID'
	>('IDLE');
	const errorMessage = errors[name as keyof T];
	const isTouched = touched[name as keyof T];
	const isDirty = Boolean(String(values[name as keyof T] ?? '').length);
	const [isFocused, setIsFocused] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		if (isFocused) {
			setFieldState('ACTIVE');
		} else if (isTouched && errorMessage && !isDirty) {
			setFieldState('INVALID_EMPTY');
			setState({ ...state, value: '' });
		} else if (isTouched && !errorMessage && isDirty) {
			setFieldState('VALID');
		} else if (!isTouched && !errorMessage && isDirty) {
			setFieldState('VALID');
		} else {
			setFieldState('IDLE');
		}
	}, [isFocused, errorMessage, values]);

	const [state, setState] = useState<{
		is: 'open' | 'closed';
		options: { display: string; value: string | number }[];
		value: string;
	}>({
		is: 'closed',
		value:
			options.find((o) => o.value === String(values[name as keyof T]))
				?.display || initialValue,
		options,
	});

	const validateRequired = (value: string) => {
		return fieldState === 'VALID' || value ? '' : t('common:errors.required');
	};

	useEffect(() => {
		setState({
			...state,
			options,
		});
	}, [options]);

	useEffect(() => {
		setState({ ...state, value: initialValue });
	}, [initialValue]);

	return (
		<div
			role="textbox"
			className="relative w-full transition-all"
			onBlur={(e: FocusEvent) => {
				if (!e.relatedTarget?.id?.includes(name)) {
					setState({ ...state, is: 'closed' });
					setIsFocused(false);
					handleBlur(e);
				}
			}}
		>
			{!shouldHideLabel && (
				<span
					className={clsx(
						'pointer-events-none absolute z-10 cursor-text transition-all duration-200',
						{
							'text-purplehaze':
								(fieldState === 'IDLE' ||
									fieldState === 'ACTIVE' ||
									fieldState === 'VALID') &&
								theme === 'dark',
							'text-ink':
								(fieldState === 'IDLE' || fieldState === 'VALID') &&
								theme === 'light',
							'text-soholights': fieldState === 'ACTIVE' && theme === 'light',
							'text-error': fieldState === 'INVALID_EMPTY',
							/* Placeholder */
							'top-0 left-4 translate-y-4 p-0 px-1.5 text-lg':
								fieldState === 'IDLE' || fieldState === 'INVALID_EMPTY',
							/* Label */
							'left-4 -translate-y-3.5 bg-hemocyanin p-1 text-sm':
								fieldState === 'ACTIVE' || fieldState === 'VALID',
							/* Light Theme */
							/* Label */
							'left-4 -translate-y-3.5 bg-ice p-1 text-sm':
								(theme === 'light' && fieldState === 'ACTIVE') ||
								(theme === 'light' && fieldState === 'VALID'),
						}
					)}
				>
					{label ?? camelCaseToTitleCase(name)}
				</span>
			)}
			<Field
				disabled={disabled}
				role="listbox"
				aria-label={label}
				name={name}
				readOnly={readOnly}
				validate={validateRequired}
				value={state.value}
				onChange={(e: ChangeEvent<HTMLInputElement>) => {
					setState({ ...state, value: e.target.value });
				}}
				tabIndex={0}
				onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => {
					if (readOnly) {
						return;
					}
					if (state.is === 'open') {
						const searchValue = e.currentTarget.value.toLocaleLowerCase();
						const results = options.filter((o) =>
							fullWidthToHalfWidth(o.display)
								.toLocaleLowerCase()
								.includes(searchValue)
						);
						results.length
							? setState({ ...state, options: results })
							: setState({ ...state, options });
					}
				}}
				onKeyDown={() => {
					if (readOnly) {
						return;
					}
					setFieldValue(name, '');
					return setState({ ...state, is: 'open' });
				}}
				onClick={() => {
					if (!shouldHideIcon || state.value || state.is === 'open') {
						setState({
							...state,
							is: state.is === 'open' ? 'closed' : 'open',
						});
					}
				}}
				onBlur={() => setIsFocused(false)}
				onFocus={() => setIsFocused(true)}
				placeholder={placeholder}
				className={clsx('w-full rounded-xl border-2 p-4 focus:outline-none', {
					'border-error': fieldState === 'INVALID_EMPTY',
					'border-ink':
						fieldState === 'IDLE' ||
						fieldState === 'VALID' ||
						(fieldState === 'ACTIVE' && theme === 'light'),
					'border-purplehaze': disabled,
					'placeholder:text-transparent':
						fieldState === 'ACTIVE' ||
						fieldState === 'VALID' ||
						fieldState === 'INVALID_EMPTY' ||
						fieldState === 'IDLE',
					'focus:border-voltage': fieldState === 'ACTIVE' && theme === 'dark',
					'focus:border-soholights':
						fieldState === 'ACTIVE' && theme === 'light',
					'bg-hemocyanin': theme === 'dark',
					'bg-ice text-ink hover:border-soholights': theme === 'light',
					'bg-purplehaze/20': disabled,
					'text-purplehaze': disabled,
				})}
			/>
			{!shouldHideIcon && (
				<button
					disabled={disabled}
					name={name}
					onClick={(event) => {
						event.preventDefault();
						setState({
							...state,
							is: state.is === 'open' ? 'closed' : 'open',
						});
					}}
					className={clsx(
						'absolute top-[30px] right-5 -translate-y-1/2 scale-75 transition-all',
						{
							'rotate-180': state.is === 'open',
						}
					)}
				>
					<IconChevronDown
						size={20}
						color={theme === 'light' ? ColorHex.ink : ColorHex.ice}
					/>
				</button>
			)}
			<div className="relative">
				{state.is === 'open' && (
					<div
						className={clsx(
							'absolute top-1 left-0 z-50 w-full rounded-xl border-2 py-2',
							{
								'border-ink bg-secondary placeholder:text-white':
									theme === 'dark',
								'border-ink bg-dustysky placeholder:text-black':
									theme === 'light',
							}
						)}
					>
						<div className="custom-scrollbar mr-2 max-h-60 overflow-auto">
							{state.options.map((o) => (
								<div
									data-testid="searchable-select-option"
									id={name + '-value' + '-select-target'}
									role="button"
									tabIndex={0}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											setFieldValue(name, o.value);
											setState({
												...state,
												is: 'closed',
												value: o.display,
											});
										}
									}}
									onClick={() => {
										setFieldValue(name, o.value);
										setState({
											...state,
											is: 'closed',
											value: o.display,
										});
									}}
									className={clsx('w-full cursor-pointer p-3', {
										'text-dustysky hover:bg-hemocyanin hover:text-ice':
											theme === 'dark',
										'text-black hover:bg-ice hover:text-black':
											theme === 'light',
									})}
									key={`${name}-${o.value}`}
								>
									{o.display}
								</div>
							))}
						</div>
					</div>
				)}
			</div>
			<ErrorMessage
				name={name}
				component={() => (
					<>
						<div className="relative">
							<div
								className={clsx('mt-2', {
									white: theme === 'dark',
									secondary: theme === 'light',
								})}
							>
								<div className="flex items-center gap-1">
									<IconError size={15} />
									<span className="block text-base leading-relaxed text-error">
										{errorMessage as ReactNode}
									</span>
								</div>
							</div>
						</div>
					</>
				)}
			/>
		</div>
	);
}
